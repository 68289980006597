<nav class="menu" *ngLet="user$ | async as user">
    <div [ngClass]="{ menu__logo: true, 'menu__logo--shrank': !expandMainSidenav }">
        <img src="assets/img/logo.svg" alt="Joynd" routerLink="/" />
    </div>

    <a class="menu__item" routerLink="/integration-search" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img" [ngClass]="{'menu__item-img--shrank': !expandMainSidenav}">search</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Search Integrations</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView)" class="menu__item"
        routerLink="/create-partner-integration" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{'menu__item-img--shrank': !expandMainSidenav}">add_circle_outline</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Add Integration</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView) && !hasCloudMillsUserRole(user)"
        class="menu__item" routerLink="/partner-users" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">person_outline</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Users</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView) || showMenuItem(user, permissions.PartnerIntegrationUserView)"
        class="menu__item" routerLink="/partner-dashboard" [queryParams]="{ integrationId: user.selectedIntegrationId }"
        routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">bar_chart</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Integration Details</div>
    </a>

    <div *ngFor="let page of pages$ | async">
        <a *ngIf="showMenuItem(user, permissions.PartnerEngagementPagesView + '.' + page.id)"
            routerLinkActive="menu__item--active" [routerLink]="'/partner-page/' + page.id"
            class="menu__item cursor-pointer">
            <mat-icon class="menu__item-img" [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">web</mat-icon>
            <div *ngIf="expandMainSidenav" class="menu__item-text">{{ page.name }}</div>
        </a>
    </div>

    <a *ngIf="showMenuItem(user, permissions.PortalEventNotificationsView)" class="menu__item"
        routerLink="/event-notifications" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img" [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">event</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Integration Events</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.ProfessionalServicesView)" class="menu__item"
        routerLink="/professional-services" routerLinkActive="menu__item--active">
        <svg [ngClass]="{ 'menu__item-img': true, 'menu__item-img--shrank': !expandMainSidenav }"
            xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
            <path fill="#4B5563"
                d="M760-600q-57 0-99-34t-56-86H354q-11 42-41.5 72.5T240-606v251q52 14 86 56t34 99q0 66-47 113T200-40q-66 0-113-47T40-200q0-57 34-99t86-56v-251q-52-14-86-56t-34-98q0-66 47-113t113-47q56 0 98 34t56 86h251q14-52 56-86t99-34q66 0 113 47t47 113q0 66-47 113t-113 47ZM200-120q33 0 56.5-24t23.5-56q0-33-23.5-56.5T200-280q-32 0-56 23.5T120-200q0 32 24 56t56 24Zm0-560q33 0 56.5-23.5T280-760q0-33-23.5-56.5T200-840q-32 0-56 23.5T120-760q0 33 24 56.5t56 23.5ZM760-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113T760-40Zm0-80q33 0 56.5-24t23.5-56q0-33-23.5-56.5T760-280q-33 0-56.5 23.5T680-200q0 32 23.5 56t56.5 24Zm0-560q33 0 56.5-23.5T840-760q0-33-23.5-56.5T760-840q-33 0-56.5 23.5T680-760q0 33 23.5 56.5T760-680ZM200-200Zm0-560Zm560 560Zm0-560Z" />
        </svg>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Professional Services</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PortalManagementView)" class="menu__item"
        routerLink="/portal-management/partner" routerLinkActive="menu__item--active">
        <!--Portal Management svg-->
        <svg [ngClass]="{ 'menu__item-img': true, 'menu__item-img--shrank': !expandMainSidenav }"
            xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
            <path fill="#4B5563"
                d="m680-80-12-60q-12-5-22.5-10.5T624-164l-58 18-40-68 46-40q-2-12-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T668-420l12-60h80l12 60q12 5 22.5 10.5T816-396l58-18 40 68-46 40q2 12 2 26t-2 26l46 40-40 68-58-18q-11 8-21.5 13.5T772-140l-12 60h-80Zm40-120q33 0 56.5-23.5T800-280q0-33-23.5-56.5T720-360q-33 0-56.5 23.5T640-280q0 33 23.5 56.5T720-200Zm-560-40v-480 172-12 320Zm0 80q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v131q-18-13-38-22.5T800-548v-92H447l-80-80H160v480h283q3 21 9.5 41t15.5 39H160Z" />
        </svg>

        <div *ngIf="expandMainSidenav" class="menu__item-text">Portal Management</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView)" class="menu__item" routerLink="/change-logs"
        routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">change_history</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Change Logs</div>
    </a>

    <a class="menu__item" routerLink="/help-topics-user" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">help_outlined</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Help</div>
    </a>

    <div [ngClass]="{ 'justify-center': !expandMainSidenav, 'justify-between': expandMainSidenav }"
        class="flex items-center mt-auto">
        <a class="menu__item" routerLink="/user-profile" routerLinkActive="menu__item--active">
            <!--User profile svg-->
            <svg [ngClass]="{ 'menu__item-img': true, 'menu__item-img--shrank': !expandMainSidenav }" width="24"
                height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.5 0L19 5.5V16.5L9.5 22L0 16.5V5.5L9.5 0ZM9.5 2.311L2 6.653V15.347L9.5 19.689L17 15.347V6.653L9.5 2.311ZM9.5 15C8.43913 15 7.42172 14.5786 6.67157 13.8284C5.92143 13.0783 5.5 12.0609 5.5 11C5.5 9.93913 5.92143 8.92172 6.67157 8.17157C7.42172 7.42143 8.43913 7 9.5 7C10.5609 7 11.5783 7.42143 12.3284 8.17157C13.0786 8.92172 13.5 9.93913 13.5 11C13.5 12.0609 13.0786 13.0783 12.3284 13.8284C11.5783 14.5786 10.5609 15 9.5 15ZM9.5 13C10.0304 13 10.5391 12.7893 10.9142 12.4142C11.2893 12.0391 11.5 11.5304 11.5 11C11.5 10.4696 11.2893 9.96086 10.9142 9.58579C10.5391 9.21071 10.0304 9 9.5 9C8.96957 9 8.46086 9.21071 8.08579 9.58579C7.71071 9.96086 7.5 10.4696 7.5 11C7.5 11.5304 7.71071 12.0391 8.08579 12.4142C8.46086 12.7893 8.96957 13 9.5 13Z"
                    fill="#4B5563" />
            </svg>

            <div *ngIf="expandMainSidenav" class="menu__item-text">My Profile</div>
        </a>
    </div>
</nav>