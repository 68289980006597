<div class="message-banner" [ngStyle]="{'display': (isClosed | async) ? 'none' : 'flex' }" *ngIf="message">
    <span>{{ message }}</span>
    <div>
        <a *ngIf="buttonName" mat-stroked-button color="primary" (click)="buttonClick.emit()">
            {{ buttonName }}
        </a>
        <button mat-icon-button (click)="closeBanner()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>