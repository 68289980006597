import { ProjectPhase } from '../../core/models/admin-control/project-phase';
import { Action } from '../../core/models/admin-control/action.model';
import { ReviewPhase } from '../../core/models/admin-control/review-phase';
import { RecordType } from '../../core/models/admin-control/record-type';
import { TeamRole } from '../../core/models/admin-control/team-role';
import { AdminControl } from '../../core/models/admin-control/admin-control.model';
import { AdminControlCode } from '../../core/enums/admin-control-code.enum';
import { Used } from 'src/app/core/models/admin-control/used.model';
import { Sections } from 'src/app/core/models/admin-control/sections.model';
import { Workflow } from 'src/app/core/models/admin-control/workflow.model';
import { OrderCreatedBy } from 'src/app/core/models/admin-control/order-created-by.model';
import { PartnerType } from 'src/app/core/models/admin-control/partner-type.model';

export type AdminControlTypes = ProjectPhase | Action | ReviewPhase | RecordType | TeamRole | Used | Sections | Workflow | OrderCreatedBy | PartnerType;

export const getAdminControlsData = (adminControls: Array<AdminControl>, code: AdminControlCode): Array<AdminControlTypes> =>
    adminControls
        .find((adminControl) => adminControl.code === code)
        ?.value?.split(', ')
        .map((x) => ({ name: x }));

export const getAdminControlsValue = (adminControls: Array<AdminControl>, code: AdminControlCode): string =>
    adminControls.find((adminControl) => adminControl.code === code)?.value;
