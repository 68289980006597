import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialImporterModule } from '../core/material-importer.module';
import { SafePipe } from '../core/pipes/safe.pipe';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { SharedDialogComponent } from './components/shared-dialog/shared-dialog.component';
import { ReviewTableDialogComponent } from '../components/dashboards/review-table-dialog/review-table-dialog.component';
import { IFrameDialogComponent } from './components/i-frame-dialog/i-frame-dialog.component';
import {
  ClientIntegrationsDialogComponent
} from '../components/dashboards/client-integrations-dialog/client-integrations-dialog.component';
import { SavePanelComponent } from './components/save-panel/save-panel.component';
import { OrderByPipe } from '../core/pipes/order-by.pipe';
import { SelectItemDialogComponent } from './components/select-item-dialog/select-item-dialog.component';
import { RequiredFieldDirective } from '../core/directives/required-field/required-field.directive';
import { FieldLengthLimitDirective } from '../core/directives/field-length-limit/field-length-limit.directive';
import { NgLetModule } from 'ng-let';
import { EffectsModule } from '@ngrx/effects';
import { CurrentUserEffects } from '../components/user-profile/state/current-user.effects';
import { SnackbarEffects } from '../core/state/snackbar/snackbar.effects';
import { StoreModule } from '@ngrx/store';
import { currentUserFeatureKey, currentUserReducer } from '../components/user-profile/state/current-user.reducer';
import { PluckPipe } from '../core/pipes/pluck.pipe';
import {
  CreateApplicationFieldItemXlatComponent
} from '../components/application-field-items/create-application-field-item-xlat/create-application-field-item-xlat.component';
import {
  CreateApplicationFieldItemCompareComponent
} from '../components/application-field-items/create-application-field-item-compare/create-application-field-item-compare.component';
import {
  ApplicationFieldXlatItemsComponent
} from '../components/application-field-items/application-field-xlat-items/application-field-xlat-items.component';
import {
  ApplicationFieldCompareItemsComponent
} from '../components/application-field-items/application-field-compare-items/application-field-compare-items.component';
import { HoyKeyCommentDirective } from '../core/directives/hotkey-field/hotkey-field.directive';
import { SendNotificationComponent } from './components/send-notification/send-notification.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ControlErrorComponent, DEFAULT_ERROR_MAP, ERROR_MAP } from './components/control-error/control-error.component';
import { EditableSelectComponent, OptionTemplateDirective } from './components/editable-select/editable-select.component';
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { HelpDetailPopupComponent } from './components/help-detail-popup/help-detail-popup.component';
import { HelpDetailPopupNosearchComponent } from './components/help-detail-popup-nosearch/help-detail-popup-nosearch.component';
import { TableModule } from '../table/table.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { EditableInputColumnComponent } from './components/editable-input-column/editable-input-column.component';
import { AutocompleteComponent, AutocompleteOptionDirective } from './components/autocomplete/autocomplete/autocomplete.component';
import { EditableTextareaDialogComponent } from './components/editable-textarea-dialog/editable-textarea-dialog.component';
import { ReadonlyTextareaWithPopupComponent } from './components/readonly-textarea-with-popup/readonly-textarea-with-popup.component';
import { SimpleAutocompleteColumnComponent } from './components/autocomplete/simple-autocomplete-column/simple-autocomplete-column.component';
import { MatSelectWithUnselectComponent } from './components/mat-select-with-unselect/mat-select-with-unselect.component';
import { SafeHTMLPipe } from '../core/pipes/safe-html.pipe';
import { UpdateUserComponent } from '../components/users/update-user/update-user.component';
import { EnumArrayPipe } from '../core/pipes/enum-array.pipe';
import { AutocompleteListItemComponent } from './components/autocomplete/autocomplete-list-item/autocomplete-list-item.component';
import { MessageBannerComponent } from './components/message-banner/message-banner.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,
  MaterialImporterModule,
  ClipboardModule,
  NgLetModule,
  NgxSliderModule,
  TableModule,
  OverlayModule,
];

const components = [
  UpdateUserComponent,
  SnackBarComponent,
  SharedDialogComponent,
  IFrameDialogComponent,
  ReviewTableDialogComponent,
  ClientIntegrationsDialogComponent,
  SavePanelComponent,
  SelectItemDialogComponent,
  CreateApplicationFieldItemXlatComponent,
  CreateApplicationFieldItemCompareComponent,
  ApplicationFieldXlatItemsComponent,
  ApplicationFieldCompareItemsComponent,
  SendNotificationComponent,
  ControlErrorComponent,
  EditableSelectComponent,
  HelpButtonComponent,
  HelpDetailPopupComponent,
  HelpDetailPopupNosearchComponent,
  SearchInputComponent,
  EditableInputColumnComponent,
  AutocompleteComponent,
  ReadonlyTextareaWithPopupComponent,
  EditableTextareaDialogComponent,
  SimpleAutocompleteColumnComponent,
  AutocompleteListItemComponent,
  MatSelectWithUnselectComponent, 
  MessageBannerComponent
];

const pipes = [SafePipe, OrderByPipe, PluckPipe, SafeHTMLPipe, EnumArrayPipe];

const directives = [
  RequiredFieldDirective,
  FieldLengthLimitDirective,
  HoyKeyCommentDirective,
  OptionTemplateDirective,
  AutocompleteOptionDirective
];

@NgModule({
  imports: [
    ...modules,
    EffectsModule.forFeature([CurrentUserEffects, SnackbarEffects]),
    StoreModule.forFeature(currentUserFeatureKey, currentUserReducer)
  ],
  declarations: [...components, ...pipes, ...directives],
  exports: [...modules, ...components, ...pipes, ...directives],
  providers: [
    {
      provide: ERROR_MAP,
      useValue: DEFAULT_ERROR_MAP
    }
  ]
})
export class SharedModule {
}
