import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss']
})
export class MessageBannerComponent {
  @Input() public message: string = '';
  @Input() public buttonName: string = ''

  @Output() public buttonClick = new EventEmitter();

  public isClosed = new BehaviorSubject<boolean>(false);

  public closeBanner(): void {
    this.isClosed.next(true);
  }
}